<template>
  <div class="dashboard">
    <div class="data">
      <span class="row  data__row select-field">
        Type of data
      </span>
      <div v-for="d in types"
           :key="d.name"
           class="row data__row"
      >
        <q-radio v-model="selectedType"
                 :val="d.value"
                 :label="d.label"
                 type="radio"
        />
      </div>


      <div v-for="d in data"
           :key="d.name"
           class="row data__row"
      >
        <span :class="dataClass">
          {{ d.label }}
        </span><span class="data__value col">
          {{ d.value ? `${d.value.toFixed(2)} ${d.unit}` : 'No data' }}
        </span>
      </div>
      <GraphCard v-for="(item,id) in graphData"
                 :key="id"
                 :title="item.name"
                 :class="(sidebarExpanded ? 'graph-card-extended' : 'graph-card')"
      >
        <graph-extended v-if="sidebarExpanded"
                        :data="item.values"
                        :unit="item.unit"
        />
        <graph-sidebar v-if="!sidebarExpanded"
                       :data="item.values"
                       :unit="item.unit"
        />
      </GraphCard>
    </div>
  </div>
</template>

<script>
import GraphCard from '@/oapps/commons/graph-card.vue';
import GraphSidebar from '@/oapps/commons/graph-sidebar.vue';
import GraphExtended from '@/oapps/commons/graph-extended.vue';

import heatmapRainbowMixin from '@/oapps/mixins/heatmapRainbow.js';
import graphMixin from '@/oapps/mixins/graph.js';

export default {
  name: 'HumidityDashboard',
  components: { GraphCard, GraphSidebar, GraphExtended },
  mixins: [heatmapRainbowMixin, graphMixin],
  props: {
    sidebarExpanded: {
      type: Boolean,
      default: false,
    },
    oapp: {
      type: Object,
      default: null,
    },
    global: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: [
        {
          label: 'Humidity',
          name: 'humidity.value',
          value: undefined,
          unit: '%',
        },
      ],
      selectedType: '',
      types: [],
    };
  },
  computed: {
    dataClass() {
      return this.sidebarExpanded ? 'data__label col-1' : 'data__label col-5';
    },
  },
  watch: {
    global: {
      handler(val) {
        if (!this.creatingData) this.createData();
        this.$emit(
          'input',
          val && val.total !== undefined && val.total !== null ? { label: `${val.total.toFixed(2)} %`, value: val.total } : null
        );
      },
      deep: true,
    },
    selectedType(val) {
      const type = this.types.find(t => t.value === this.selectedType);
      if (type) {
        this.$store.commit('oapp/update', {oappId: this.oapp.id, data: { type: type.value }});
        this.$store.dispatch('oapp/fetchCurrentOappData');
      }
    },
  },
  created() {
    this.types = this.oapp.types.map(t => ({ label: t, value: t }));
    this.selectedType = this.oapp.type;
  },
};
</script>

<style lang="stylus" scoped>
    @import '~variables'

    .dashboard
        .select-field
            font-size $fs-h3
            font-weight 700
            top 8px
            left 10px
        .type-selector
            margin 10px 10px 0px 10px
        .data
            padding 20px
            .data__row
                margin-left 10px
                padding 3px
            .data__label
                font-weight 700
                font-size $fs-h3
            .data__value
                font-size $fs-h3
        .graph-card
            margin-bottom $border-thick
            max-width 400px
            width 100%
            &:last-child
                margin-bottom 0
        .graph-card
            max-width 2000px
</style>
